import React, { Fragment, Suspense, lazy } from 'react'
import { HashRouter, Route, Switch } from 'react-router-dom'
import Header from './components/layout/Header'
import Footer from './components/layout/Footer'
import Spinner from './components/Spinner'

const Home = lazy(() => import('./pages/Home'))
const Detail = lazy(() => import('./pages/Detail'))

function App() {
  return (
    <Suspense fallback={<Spinner size={60} />}>
      <HashRouter>
        <Fragment>
          <Header />
            <main className="container mt-4 mb-2">
              <Switch>
                <Route path="/:page?" exact component={Home} />
                <Route path="/detail/:slug" exact component={Detail} />
              </Switch>
            </main>
          <Footer />
        </Fragment>
      </HashRouter>
    </Suspense>
  )
}

export default App
