import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHeart } from '@fortawesome/free-solid-svg-icons'
function Footer() {
  return (
    <footer className="w-100" style={{ bottom: 0, left: 0, background: '#121640' }}>
      <div className="container py-3">
        Made with <FontAwesomeIcon icon={ faHeart}/>&nbsp;by Hyraze
      </div>
    </footer>
  )
}

export default Footer
