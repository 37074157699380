import React from 'react'
import { Link } from 'react-router-dom'

import logo from '../../assets/logo.png'
import { useMedia } from '../../hooks'

function Header() {
  const smAndDown = useMedia('(max-width: 767.98px)')

  return (
    <header style={{ background: '#121640' }}>
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <Link
            to="/"
            className="decoration-none outline-none d-flex align-items-center text-light"
            style={{ transform: `translateX(${smAndDown ? '-10px' : '-14px'})` }}
          >
            <img src={logo} alt="logo" width={smAndDown ? 50 : 70} />
            <span className={`${smAndDown ? 'm-0 h4' : 'mt-1 h1'}`}>demium</span>
            </Link>
            <p className={`${smAndDown ? 'm-1 h12' : 'mt-4 h10'}`}><a  href="https://gitrepos.xyz"
                target="_blank"
                rel="noopener noreferrer"> &nbsp;Powered by Gitrepos.xyz</a></p>
        </div>
      </nav>
    </header>
  )
}

export default Header
