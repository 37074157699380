import { useState, useEffect } from 'react'

const request = async uri => {
  try {
    const response = await fetch(`/.netlify/functions/${uri}`)

    if(response.status !== 200) {
      throw response.status
    }

    const json = await response.json()
    return json
  }
  catch(e) {
    throw e
  }
}

export const useFetch = (url, initValue, deps = []) => {
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(initValue)

  useEffect(() => {
    setLoading(true)

    request(url).then(json => {
      setData(json)
      setLoading(false)
    })

    // eslint-disable-next-line
  }, [...deps])

  return [loading, data]
}

export const useMedia = query => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches)

  useEffect(() => {
    const media = window.matchMedia(query)
    if(media.matches !== matches)
      setMatches(media.matches)

    const listener = () => setMatches(media.matches)
    media.addListener(listener)

    return () => media.removeListener(listener)
    // eslint-disable-next-line
  }, [])

  return matches
}
