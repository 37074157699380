import React from 'react'

function Spinner({ size, height }) {
  return (
    <div className="w-100 d-flex justify-content-center align-items-center" style={{ height: height ? height : '80vh' }}>
      <div className="overflow-hidden" style={{ width: size, height: size }}>
        <div className="spinner-inner">

          {Array.from({ length: 3 }).map((_, i) => (
            <div key={i} className="position-absolute w-100 h-100 rounded-circle spinner-line" />
          ))}

          <div className="d-block position-absolute spinner-circle">
            &#9679;
          </div>
        </div>
      </div>
    </div>
  )
}

export default Spinner
